import React from 'react';
import ReactDOM from 'react-dom';
import './dist/css/tailwind.css';
import App from './App';

//import * as serviceWorker from './serviceWorker';


import store from './store/index';
import { Provider } from 'react-redux'; 
import axios from 'axios';
import cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
import { ServerLink } from './keys/Links';



//const jwt_secret = 'Tvg6La5087pkNZvNU2cu3h8h3ZG5kcJDhyhYBv14ZGw89O6J4TcWlsbmmzEqyILX'; old version key
const jwt_secret = 'Yl5N69u6k8PAb3krARXpmI5YAf7kDvxvCf3lX77TmF6IAcUFsnTZRzAHoqDPEPPp';
let token = cookie.get("token");



if(token){

  jwt.verify(token, jwt_secret, (err, decoded) =>{
 
    if(err){
      cookie.remove("token");
      token = null;
      // console.log('remove token 1')
    }
    else{
      if( ServerLink+'/register'  !== decoded.iss && ServerLink+'/login' != decoded.iss){
          cookie.remove("token");
          token = null;

       //console.log(decoded.iss)
       //console.log(ServerLink+'/register')
      }
    }
  
    //console.log(decoded);
  });
}




const Render = ()=>{

ReactDOM.render(
  
  <Provider store={store}>
    <React.StrictMode>
      <App />
      
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
}

if(token){

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axios.get(ServerLink+'/me').then(
    res=>{
      store.dispatch({type: "SET_LOGIN", payload: res.data.user});
      //console.log(res.data.user)
      Render();
    });  
}

else{
  Render();
}





/*
ReactDOM.render(
  <React.StrictMode>
    <App />
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

*/