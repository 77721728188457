import React, {Component} from 'react'
import Structure from '../../inc/structure';





class Notifications extends Component{




    render(){


        return(
            <Structure title="Notifications">

            </Structure>
        );
    }
}



export default Notifications;