import React, {Component} from 'react';
import Structure from '../../inc/structure';




class Loan extends Component{



    render(){

        return(
           <Structure title="Loan Access">

                <div className="cardx">
                    <div className="headerx">
                        <h2 className="workSanBold text-sm sm:text-2xl">Loan Application</h2>
                    </div>


                    <div className="text-center w-full">
                        <img src={require('./../../dist/images/undraw/under-construction.png')} width={500} className="mx-auto" />

                        <div>
                            <h3 className="mt-5 f-raleway">Study Purse Loan Application is Under Development</h3>
                        </div>
                    </div>

               </div>
               
           </Structure>
        )
    }
}


export default Loan;