import React, {Component} from 'react'
import GetApi from '../../api_request/Get';
import notifyError from '../../inc/SnackbarError';
import { CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import {connect} from 'react-redux'
import randomString from 'random-string';
import { RedirectLink, ServerLink } from '../../keys/Links';
import postApi from '../../api_request/Post';
import Modal, { ModalCenter } from '../modals/Modal';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

class AddMoney extends Component{


    constructor(props){
        super(props);

        this.state = {
            cards:[],
            isLoading: false,
            isLoading2: false,
            amount: 0,
            selectCard: 'new',
            selectPurse: 'default',
            modalSuccess: false,
            savings:[],
            studyBalance:'',
            purseTarget:'',
            totalSavings:''


        }
    }

    componentDidMount(){

        //get the Card
        GetApi(ServerLink+'/cards').then(
            res=>{
                console.log(res)

                if(res.status == true){

                    this.setState({
                        cards: res.cards,
                        isLoading: false
                    })
                }
                else{

                    notifyError(res.error, 'error','top-center')


                    this.setState({
                        isLoading: false
                    })
                }
            }
        ).catch(e=>{
            console.log(e)

            notifyError('Oops.. Something went wrong, check your internet connection', 'error','top-center')
            this.setState({
                isLoading: false
            })
        })


        this.getPurse();

    }



    getPurse =(e)=>{

        //e.preventDefault();

        this.setState({
            isLoading: true
        })
         //get the Card
         GetApi(ServerLink+'/user/savings?q=all').then(
            res=>{

                if(res.status == true){

                    console.log(res)

                    this.setState({
                        savings: res.data,
                        studyBalance: res.purse_balance[0].study_balance,
                        purseTarget: res.purse_balance[0].target,
                        totalSavings: res.totalSavings,
                        isLoading: false
                    })
                }
                else{

                    notifyError(res.message, 'error','top-center')


                    this.setState({
                        isLoading: false
                    })
                }
            }
        ).catch(e=>{
            console.log(e)

            //notifyError('Oops.. Something went wrong, check your internet connection', 'error','top-center')
            notifyError(e, 'error','top-center')
            this.setState({
                isLoading: false
            })
        })
    }



    modalHandleSuccess = (e) => {
        e.preventDefault();

        console.log("hello");
    
        this.setState(
            {
                modalSuccess: !this.state.modalSuccess,

            }
        )
    }

    rediectx = (e)=>{
        window.location.reload();
    }



    //transaction method

    confirmTransaction=(tran_id, userId, txRef) =>{
        
        //e.preventDefault();
        this.setState({
            isLoading: false,
            modalSuccess: !this.state.modalSuccess,
        })


        //verify token transaction

        const data = {
            'id': tran_id,
            'user_uid': userId,
            'tx_ref': txRef
        }

                     
        postApi(ServerLink+'/payment/verify_token_payment', data).then(
            res=>{

                if(res.status == true){

                    this.setState({
                        isLoading2: false,
                    })
                    console.log(res)

                }else{

                    console.log(res)
                    notifyError('Oops, Something went wrong', 'error', 'top-center')

                    this.setState({
                        isLoading: true
                    })
                }
                }).catch(e=>{
                    console.log(e)
                })

        //console.log(tran_id)
        //console.log(userId)
        //console.log(txRef)
    }

    handleInput = (i) => {
        i.preventDefault();
        const name = i.target.name;
        const value = i.target.value;
        this.setState({[name]:value});
        //console.log(value)
    }

    handleSubmit = (e)=>{

        e.preventDefault();

        this.setState({
            isLoading: true
        })

        var trasactionRandId = 'StudyPurse-' + randomString({length: 10});

        //for reuseable cards i.e charge authorization

        if(this.state.selectCard !== 'new'){

            //console.log(this.state.selectCard)

            const dataRave = {
                'token': this.state.selectCard,
                'amount': this.state.amount,
                'tx_ref': trasactionRandId,
                'currency':this.props.userInfo.currency,
                'country':this.props.userInfo.country,
                'firstname': this.props.userInfo.firstname,
                'lastname': this.props.userInfo.lastname,
                'email': this.props.userInfo.email,
                'narration': 'Charges for study purse saving',
                'meta': {
                    'firstname': this.props.userInfo.firstname,
                    'lastname': this.props.userInfo.lastname,
                    'email': this.props.userInfo.email,
                    'phonenumber': this.props.userInfo.phone,
                    'autosave': 'false',
                    'purseID':this.state.selectPurse,
                    'user_uid': this.props.userInfo.user_uid,
                    'webredirect_url': RedirectLink+'/#/dashboard',
                    
                }
            }

             
            postApi(ServerLink+'/payment/charge_authorization', dataRave).then(
                res=>{

                    if(res.status == 'success'){

                        this.setState({
                            isLoading: false,
                            //modalSuccess: !this.state.modalSuccess,
                        })

                        //query payment
                        const resInfo = {
                            'tx_ref': trasactionRandId,
                            'ref': trasactionRandId,
                            'transaction_id': res.data['id'],
                        }

                        //confirm transaction
                        var tran_id = res.data['id'];
                        var userId = this.props.userInfo.user_uid;
                        var txRef = trasactionRandId;

                        this.confirmTransaction(tran_id, userId, txRef);

                        //redirect to url
                        ///window.location.replace('payment_success');
                    }
    
                    else{

                        console.log(res)
                        notifyError('Oops, Something went wrong', 'error', 'top-center')
    
                        this.setState({
                            isLoading: true
                        })
                    }
                   
                    console.log(res)
                }
            ).catch( e=>{
                console.log(e)
    
                this.setState({
                    isLoading: false
                })
            })
        }
        //for new card payment
        else{
            
            const data = {
                'amount': this.state.amount*100,
                'email': this.props.userInfo.email,
                'callback_url': ServerLink+'/payment/transaction',
                'reference': trasactionRandId,
                'metadata': {
                    'firstname': this.props.userInfo.firstname,
                    'lastname': this.props.userInfo.lastname,
                    'user_uid': this.props.userInfo.user_uid,
                    'redirect_url': RedirectLink+'/#/dashboard',
                    'autosave': false
                }
            }

            const dataRave = {
                'amount': this.state.amount,
                'redirect_url': ServerLink+'/payment/transaction',
                'tx_ref': trasactionRandId,
                'currency':this.props.userInfo.currency,
                'payment_options':'card,bank,ussd',
                'meta': {
                    'firstname': this.props.userInfo.firstname,
                    'lastname': this.props.userInfo.lastname,
                    'email': this.props.userInfo.email,
                    'phonenumber': this.props.userInfo.phone,
                    'autosave': 'false',
                    'user_uid': this.props.userInfo.user_uid,
                    'webredirect_url': RedirectLink+'/#/dashboard',
                },

                'customer': {
                    'name': this.props.userInfo.firstname +' '+this.props.userInfo.lastname,
                    'email': this.props.userInfo.email,
                    'phonenumber': this.props.userInfo.phone,
                },
                "customizations":{
                    "title":"Study Purse",
                    "description":"Middleout isn't free. Pay the price",
                    "logo":"http://studypurse.herokuapp.com/static/media/slogo2.3fdc8404.jpg"
                 }
              
            }

            //postApi(ServerLink+'/payment/paystack', data).then(
            postApi(ServerLink+'/payment/saverave', dataRave).then(

                res=>{
    
                    // if(res.status == true){
                    //     this.setState({
                    //         isLoading: false
                    //     })
    
                    //     window.location.replace(res.data.authorization_url);
                    // }


                    if(res.status == 'success'){
                        this.setState({
                            isLoading: false
                        })
    
                        window.location.replace(res.data.link);
                    }
    
                    else{
                        notifyError('Oops, Something went wrong', 'error', 'top-center')
    
                        this.setState({
                            isLoading: false
                        })
                    }
                   
                    console.log(res)
                }
            ).catch( e=>{
                console.log(e)
    
                this.setState({
                    isLoading: false
                })
            })
    
            console.log(this.state.selectCard)
        }
    }


    ModalContentSuccess = () => {

        if (this.state.modalSuccess) {

            return (
                <Modal show={this.state.modalSuccess} modalClosed={this.modalHandleSuccess}>
                    <div>

                        

                    </div>
                </Modal>
            )
        }
    }



    render(){

        return(
            <div>
                
                <Modal show={this.state.modalSuccess} modalClosed={this.modalHandleSuccess}>
                    <div>
                    {
                     this.state.isLoading2 ?

                        <div className="pt-40">

                            <h4 className="text-center">Loading...</h4>
                        </div>

                        :

                        <div className="">
                            <a onClick={this.rediectx} className="float-right">
                                    <FeatherIcon icon="x-circle" />
                            </a>

                            <div className="pt-30">
                                <img src={require('./../../dist/images/undraw/card-success.jpg')} className="rounded-full" />

                                <div className="text-center">
                                    <h3 className="f-Raleway text-blue-700 mb-10">Card Charge Successfully</h3>

                                    <Link to='/history' className="py-3 px-4 mt-5 text-white bg-blue-400"> See Saving Histories</Link>
                                </div>
                            </div>


                        </div>

                    }


                    </div>
                </Modal>




                 <form onSubmit={this.handleSubmit} method="POST">
                    <div className="mb-5 text-center">
                        <h3 className="workSanBold">Add Money</h3>
                        <p className="workSan">Fund your study purse</p>
                    </div>
                    <div className="">
                        <label className="workSanMedium">Amount</label>
                        <input name="amount" onChange={this.handleInput} className="px-2 py-3 border w-full placeholder-gray-600" type="number" placeholder="5000" />
                    </div>


                    <div className="mt-3">
                        <label className="workSanMedium">Select Purse</label>

                        <select name="selectPurse" onChange={this.handleInput} className="px-2 py-3 border w-full">
                            <option value="new">Study Purse</option>
                            {
                                this.state.savings.map((purse, index)=> (
                                    <option value={purse.savings_uid}> {purse.title} </option>
                            ))}
                            

                        </select>
                    </div>

                    <div className="mt-3">

                        <label className="workSanMedium">Select Card</label>
                        <select name="selectCard" onChange={this.handleInput} className="px-2 py-3 border w-full">
                            <option value="new">New Card</option>
                            {
                                this.state.cards.map((card, index)=> (
                                    <option value={card.authorization_id}> *** {card.last4} </option>
                            ))}
                            

                        </select>
                    </div>



                    <div className="mb-20">
                        {
                        this.state.isLoading ?

                            <button type="button" className="w-full py-3 text-center px-2 bg-blue-400 text-white mt-5">Loading ....</button>
                            
                            :

                            <button type="submit" className="w-full py-3 text-center px-2 bg-blue-800 text-white mt-5">Save</button>
                        }
                    </div>

                    {/* <div className="">
                        <button type="submit" className="bg-orange-600 text-white" onClick={this.modalHandleSuccess}> Success </button>
                    </div> */}
                </form>
            </div>
        )
    }
}



const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


export default connect(mapStateToProps, '')(AddMoney)