import React, { Component, useEffect } from 'react';

import { HashRouter, Route, Switch } from 'react-router-dom';



//import './App.css';

import './dist/css/studypurse.css'
import './dist/css/studypurse-device.css'
import './dist/css/bootstrap.min.css';

import { Spinner } from 'react-bootstrap';
import NProgress from 'nprogress';


import GuestRoute from './components/GuestRoute';
import AuthRoute from './components/AuthRoute';


import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import HelmetMetaData from './inc/HelmetMetaData';
import Loan from './home/loan/Loan';
import Notifications from './home/notification/Notifications';
import Purse from './home/savings/Purse';
import Colleges from './home/colleges/Colleges';




const loading2 = (
  <div className="pt-56 my-0 text-center py-64 h-screen bg-white">

    <img src={require('./dist/images/studypurse2.gif')} width={200} className="m-auto" />
  </div>
);



const About = React.lazy(() => import('./pages/about'))
const Campaigns = React.lazy(() => import('./pages/campaign/campaigns'))
const Campaign = React.lazy(() => import('./pages/campaign/campaign'))
const Faqs = React.lazy(() => import('./pages/faq'))
const Donation = React.lazy(() => import('./pages/Donation'))
const Success = React.lazy(() => import('./pages/campaign/Success'))

const Dashboard = React.lazy(() => import('./home/dasboard/Dashboard'))
const Payment = React.lazy(() => import('./home/payment/Payment'))
const Savings = React.lazy(() => import('./home/savings/Savings'))
const History = React.lazy(() => import('./home/history/History'))
const Referral = React.lazy(() => import('./home/referral/Referral'))
const Application = React.lazy(() => import('./home/application/Application'))

const Fundraise = React.lazy(() => import('./home/campaign/Fundraise'))
const Courses = React.lazy(() => import('./home/mycourse/Course'))
const Chances = React.lazy(() => import('./home/mychance/Chance'))


const Schools = React.lazy(() => import('./pages/schools/schools'))
const School = React.lazy(() => import('./pages/schools/school'))
const SearchResult = React.lazy(() => import('./pages/schools/SearchResult'))

const Profile = React.lazy(() => import('./home/profile/Profile'))
const Landing = React.lazy(() => import('./Landing'))

//Login
const Login = React.lazy(() => import('./authentication/Login'))

//Register
const Register = React.lazy(() => import('./authentication/Register'))

//Complete
const Complete = React.lazy(() => import('./authentication/Complete'))

class App extends Component {

  render() {
    return (

      <div>

        <HashRouter>
          <React.Suspense fallback={loading2}>
            <HelmetMetaData></HelmetMetaData>
            <Switch>

              <Route path='/' exact render={props => <Landing {...props} />} />
              <Route path='/about' exact render={props => <About {...props} />} />
              <Route path='/faq' exact render={props => <Faqs {...props} />} />
              <Route path='/campaigns' render={props => <Campaigns {...props} />} />
              <Route path='/campaign/:id' render={props => <Campaign {...props} />} />
              <Route path='/donate/:id' render={props => <Donation {...props} />} />
              <Route path='/schools' render={props => <Schools {...props} />} />
              <Route path='/school/:id' render={props => <School {...props} />} />

              <Route path='/search' render={props => <SearchResult {...props} />} />

              <Route path='/success/:id' render={props => <Success {...props} />} />

              <GuestRoute path='/login' exact component={Login} />
              <GuestRoute path='/register' exact component={Register} />
              <GuestRoute path='/complete-registration' exact component={Complete} />

              <AuthRoute path='/dashboard' component={Dashboard} />
              <AuthRoute path='/payment' exact component={Payment} />
              <AuthRoute path='/savings' component={Savings} />
              <AuthRoute path='/history' component={History} />
              <AuthRoute path='/referral' component={Referral} />
              <AuthRoute path='/application' component={Application} />
              <AuthRoute path='/fundraise' component={Fundraise} />
              <AuthRoute path='/profile' component={Profile} />
              <AuthRoute path='/mycourses' component={Courses} />
              <AuthRoute path='/mychances' component={Chances} />
              <AuthRoute path='/search-colleges' component={Colleges} />
              <AuthRoute path='/college/:id' component={Colleges} />
              <AuthRoute path='/loan' component={Loan} />
              <AuthRoute path='/notifications' component={Notifications} />
              <AuthRoute path='/mypurse/:id' component={Purse} />

            </Switch>
          </React.Suspense>
        </HashRouter>
        <ToastContainer className="f-visby" />
      </div>
    );
  }
}

export default App;
