import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';

import { ListGroup, Container, Row, Col } from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';
import { render } from 'react-dom';

import { connect } from 'react-redux';

import cookie from 'js-cookie';


class Structure extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            hidex: '',
            user: this.props.userInfo,
        };

    }

    state = {
        navStatus: 0,
    }

    handleLogout = (e) => {

        e.preventDefault();
        cookie.remove('token');
        this.props.loggout();

    }






    openNav = () => {

        if (this.state.open == true) {
            this.setState({
                open: false,
                hidex: 'hidden'
            })

            document.getElementById('myNav').style.width = '250px';
            document.getElementById('mainBody').style.left = '250px';
            document.getElementById('mainBody').style.width = '500px';
            //tags[0].setAttribute('hidden', "hidden")
        }

        else {

            this.setState({
                open: true,
                hidex: 'visible'
            })
            document.getElementById('myNav').style.width = '0px'
            document.getElementById('mainBody').style.left = '0'
            document.getElementById('mainBody').style.width = '100%';
        }

    }

    handleLogout = (e) => {

        e.preventDefault();

        cookie.remove('token');
        cookie.remove('refresh');
        this.props.loggout();

    }

    render() {

        const { open, hidex } = this.state;

        return (

            <div>

                <div className="">

                    <div className="myNav shadow-2xl" id="myNav" style={{}}>



                        <div className="sideNav">

                            <div className="text-left pb-3 bg-whitex pt-3">

                                <Link to="/">
                                    <span className="inline-block float-left">
                                        <img src={require('./../dist/images/slogo.png')} className="ml-2 mb-5" width={30} />

                                    </span>
                                    <span className="inline-block mt-1 ml-3 text-black workSanMedium f-s-18">Study Purse</span>
                                </Link>
                            </div>

                            <ul className="mt-5">
                                <li className="navx"><NavLink activeClassName="active" to="/dashboard"> <i className="fa fa-home"></i> <span className="navText"> Home </span> </NavLink></li>

                                <li className="navx" ><NavLink to="/savings"> <i className="fa fa-piggy-bank"></i>  <span className="navText">My Purses </span></NavLink></li>

                                <li className="navx"><NavLink to="/history"><i className="fa fa-history"></i> <span className="navText"> History </span></NavLink></li>

                                <li className="navx"><NavLink to="/loan"><i className="fa fa-gift"></i><span className="navText"> Loan <small className="float-right badge badge-danger"> Coming Soon </small> </span></NavLink></li>

                                {/* <li className="navx"><NavLink to="/fundraise"><i className="fa fa-donate"></i><span className="navText"> Fundraising </span></NavLink></li> */}

                                <li className="navx"><NavLink to="/payment"> <i className="fa fa-credit-card"></i> <span className="navText"> Payment </span></NavLink></li>



                                <li className="navx"><NavLink to="/search-colleges"><i className="fa fa-search"></i><span className="navText"> Explore Colleges </span></NavLink></li>
                                <li className="navx"><NavLink to="/application"><i className="fa fa-list"></i> <span className="navText"> My College List</span></NavLink></li>
                                <li className="navx"><NavLink to="/mycourses"><i className="fa fa-paste"></i> <span className="navText"> My Preferences</span></NavLink></li>
                                {/* <li className="navx"><NavLink to="/mychances"><i className="fa fa-dice-six"></i> <span className="navText"> My Chances</span></NavLink></li> */}





                                {/* <li className="navx"><NavLink to="/status"><i className="fa fa-battery-half"></i><span className="navText"> Status </span></NavLink></li> */}
                                <li className="navx"><NavLink to="/profile"><i className="fa fa-cogs"></i><span className="navText"> Profile  Settings</span></NavLink></li>
                                {/* <li className="navx"><NavLink to="/referral"><i className="fa fa-gift"></i><span className="navText"> Referral </span></NavLink></li> */}

                                <li className=""><Link onClick={this.handleLogout}><FeatherIcon icon="log-out" className="inline-block f-s-20" hidden /> <i className="fa fa-sign-out-alt"></i><span className="navText">Logout</span> </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="mainBody pb-4" id="mainBody">
                    <div className="structure-header lg:hiddenx">

                        <Container>
                            <Row>

                                <Col xs={2} sm={3}>
                                    <div className="pl-3 pt-4 mb-4 mobileView">
                                        <Link onClick={this.openNav}>
                                            <FeatherIcon icon="menu" className="text-gray-300" style={{ fontSize: '50px' }} />
                                        </Link>
                                    </div>
                                </Col>

                                <Col xs={7} sm={10}>
                                    <div className="float-left inline-block pt-4" hidden>
                                        <Link onClick={this.openNav}>
                                            <FeatherIcon icon="menu" className="text-gray-300" style={{ fontSize: '50px' }} />
                                        </Link>
                                    </div>

                                    <div className="pt-4 text-center">
                                        <h6 style={{ fontWeight: 'bolder' }} className={this.props.colorx + " text-center f-s-18 workSanMedium f-w-700 p-t-8 workSan"}>{this.props.title} </h6>
                                    </div>
                                </Col>

                                <Col xs={3} sm={2}>
                                    <div className="pt-4 flex flex-row">


                                        <div className="mr-3">
                                            <Link to="notifications" className="text-white">

                                                <FeatherIcon icon="bell" className="fa fa-bell block z-0 relative" style={{ marginTop: '10px', marginLeft: '-20x' }} />
                                                <span className="bg-red-700 w-6 h-6 block text-center rounded-full z-50 absolute f-ubuntu" style={{ marginTop: '-35px', marginRight: '5px' }}>01</span>
                                            </Link>
                                        </div>



                                        <div className="bg-gray-100 h-8 w-8 rounded-full mr-2">
                                            {
                                                !this.props.userInfo.picture ?

                                                    <img src={require('./../dist/images/icons/user.png')} />
                                                    :
                                                    <img src={this.props.userInfo.picture} />
                                            }
                                        </div>
                                        <span className="workSanMedium mt-1 hidden sm:block">{this.props.userInfo.firstname}</span>
                                        <FeatherIcon icon="arrow-down" className="mt-1" />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="lg:pt-4" style={{ position: 'relative', minHeight: '100vh' }}>


                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {

    return {
        loggedIn: state.auth.loggedIn,
        userInfo: state.auth.user

    }
}


const mapDispatchProps = dispatch => {

    return {
        loggout: () => dispatch({ type: 'SET_LOGOUT', })
    }
}

export default connect(mapStateToProps, mapDispatchProps)(Structure);




//export default Structure;