//export const ServerLink = "http://localhost:8000/api";
//export const RedirectLink = "http://127.0.0.1:3000"

export const ServerLink = "https://apisp.dinduenergy.com/api";
export const RedirectLink = "https://sp.dinduenergy.com"

//export const ServerLink = "https://v1.api.studypurse.com/api";
//export const ServerLink = "https://api.studypurse.com/api";


