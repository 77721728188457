import React, {Component} from 'react'
import './../../dist/css/debit-card.css'
import GetApi from '../../api_request/Get';
import notifyError from '../../inc/SnackbarError';
import { RedirectLink, ServerLink } from '../../keys/Links';
import FeatherIcon from 'feather-icons-react';

class DebitCard extends Component{




    constructor(props){
        super(props);

        this.state = {
            cards:[],
            isLoading: true,
          
        }
    }

    componentDidMount(){

        //get the Card
        GetApi(ServerLink+'/cards').then(
            res=>{
                console.log(res)

                if(res.status == true){

                    this.setState({
                        cards: res.cards,
                        isLoading: false
                    })
                }
                else{

                    notifyError(res.error, 'error','top-center')


                    this.setState({
                        isLoading: false
                    })
                }
            }
        ).catch(e=>{
            console.log(e)

            notifyError('Oops.. Something went wrong, check your internet connection', 'error','top-center')
            this.setState({
                isLoading: false
            })
        })

    }

    
    render(){
        const {isLoading, cards} = this.state;
        return(

            <>
                {
                    isLoading ? 
                    <div>
                        Loading ....
                    </div>

                    :

                    cards.map((card,index)=>(

                        <div className="lg:col-span-4 xl:col-span-4 col-span-12">
                            <div className="cardATM">
                                <div className="card__front card__part2">
                                    
                                    <img className="card__front-square card__square" src={
                                        card.card_type =='MASTERCARD' ? require('./../../dist/images/icons/mastercard.png') 
                                        : card.card_type =='VISA' ? require('./../../dist/images/icons/visa.png') 
                                        : card.card_type =='MAESTRO' ? require('./../../dist/images/icons/maestro.png')
                                        : card.card_type =="VERVE" ? require('./../../dist/images/icons/verve11.jpg') : ''} />

                                    {/* <img className="card__front-logo card__logo" src="https://www.fireeye.com/partners/strategic-technology-partners/visa-fireeye-cyber-watch-program/_jcr_content/content-par/grid_20_80_full/grid-20-left/image.img.png/1505254557388.png" /> */}
                                   
                                    <a onClick={()=>alert('Are you sure you want to delete')}>
                                        <FeatherIcon icon="trash" size="17" className="float-right text-red-600" style={{'marginTop':'-30px'}} />
                                    </a>
                                    <p className="card_numer">**** **** **** {card.last4}</p>
                                    <div className="card__space-75">
                                    <span className="card__label">Card holder</span>
                                    <p className="card__info">{card.card_holder }</p>
                                    </div>
                                    <div className="card__space-25">
                                    <span className="card__label">Expires</span>
                                    <p className="card__info">{card.exp_month+'/'+card.exp_year}</p>
                                    </div>
                                </div>

                                <div className="card__back card__part2">
                                    <div className="card__black-line"></div>
                                    <div className="card__back-content">
                                    <div className="card__secret">
                                        <p className="card__secret--last">420</p>
                                    </div>
                                    <img className="card__back-square card__square" src="" />
                                    <img className="card__back-logo card__logo" src="https://www.fireeye.com/partners/strategic-technology-partners/visa-fireeye-cyber-watch-program/_jcr_content/content-par/grid_20_80_full/grid-20-left/image.img.png/1505254557388.png" />

                                    </div>
                                </div>
                            </div>
                        </div>
            
                    ))

                }
                
            
            </>
        )
    }
}


export default DebitCard