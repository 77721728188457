import React, {Component } from 'react'
import FeatherIcon from 'feather-icons-react'
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Structure from '../../inc/structure';



class Colleges extends Component{


    render(){

        return(

            <Structure title="Explore Colleges">
                <div className='cardx'>
                    <div className="lg:col-span-12 col-span-12">
                                         
                <div className="">
                    
                    <p className='f-raleway'>Showing 100 results for University.</p>
                </div>

    <div className='grid grid-cols-12 md:gap-4 gap-5 mt-10'>

        <div className='md:col-span-4 col-span-12 mb-5'>
            <div className='shadow-lg rounded-b-md' style={{minHeight:'300px'}}>
                <div className=''>
                    <div className='imgBox'>
                        <img src={require('./../../dist/images/schools/alberta3.jpg')} className='imgFix' />
                    </div>

                    <div className='grid grid-cols-12 px-3' style={{marginTop: '-80px'}}>
                        <div className='col-span-6'>
                            <div className='collegeLogo'>
                                <img src={require('./../../dist/images/schools/logo/alberta.jpg')} />
                            </div>
                        </div>

                        <div className='col-span-6 pt-4'>
                            <div className='h-16 w-16 py-2 text-center rounded-full bg-white shadow-lg float-right'>

                                <FontAwesome type='button' name="heart" size='1.5' className="text-2xl mt- text-blue-200" style={{marginTop: '14px'}} />
                            </div>
                        </div>
                    </div>

                    <div className='py-1 mt-2 px-4'>
                        <h5 className='workSanMedium text-gray-700'>University of Alberta</h5>
                        <span className='block text-blue-600 f-raleway'>Canada</span>


                        <div className='grid grid-cols-12 my-3 gap-4'>
                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Avg. Price</span>
                            

                                <NumberFormat prefix="₦" 
                                    displayType="text" 
                                    thousandSeparator={true}
                                    value={3000000} /> 
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Courses </span>
                                <bold className="block f-visby">300</bold>
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'> App. Fee </span>
                                <bold className="block f-visby">$200</bold>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className='md:col-span-4 col-span-12 mb-5'>
            <div className='shadow-lg rounded-b-md' style={{minHeight:'300px'}}>
                <div className=''>
                    <div className='imgBox'>
                        <img src={require('./../../dist/images/schools/harvard.jpg')} className='imgFix' />
                    </div>

                    <div className='grid grid-cols-12 px-3' style={{marginTop: '-80px'}}>
                        <div className='col-span-6'>
                            <div className='collegeLogo'>
                                <img src={require('./../../dist/images/schools/logo/harvard.jpg')} />
                            </div>
                        </div>

                        <div className='col-span-6 pt-4'>
                            <div className='h-16 w-16 py-2 text-center rounded-full bg-white shadow-lg float-right'>

                                <FontAwesome type='button' name="heart" size='1.5' className="text-2xl mt- text-blue-200" style={{marginTop: '14px'}} />
                            </div>
                        </div>
                    </div>

                    <div className='py-1 mt-2 px-4'>
                        <h5 className='workSanMedium text-gray-700'>Harvard University</h5>
                        <span className='block text-blue-600 f-raleway'>Canada</span>


                        <div className='grid grid-cols-12 my-3 gap-4'>
                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Avg. Price</span>
                                <bold className="block f-visby">
                                    <NumberFormat prefix="₦" 
                                    displayType="text" 
                                    thousandSeparator={true}
                                    value={23000000} /> 
                                </bold>
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Courses </span>
                                <bold className="block f-visby">300</bold>
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'> App. Fee </span>
                                <bold className="block f-visby">$200</bold>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className='md:col-span-4 col-span-12 mb-5'>
            <div className='shadow-lg rounded-b-md' style={{minHeight:'300px'}}>
                <div className=''>
                    <div className='imgBox'>
                        <img src={require('./../../dist/images/schools/standford.jpg')} className='imgFix' />
                    </div>

                    <div className='grid grid-cols-12 px-3' style={{marginTop: '-80px'}}>
                        <div className='col-span-6'>
                            <div className='collegeLogo'>
                                <img src={require('./../../dist/images/schools/logo/stanford.jpg')} />
                            </div>
                        </div>

                        <div className='col-span-6 pt-4'>
                            <div className='h-16 w-16 py-2 text-center rounded-full bg-white shadow-lg float-right'>

                                <FontAwesome type='button' name="heart" size='1.5' className="text-2xl mt- text-blue-200" style={{marginTop: '14px'}} />
                            </div>
                        </div>
                    </div>

                    <div className='py-1 mt-2 px-4'>
                        <h5 className='workSanMedium text-gray-700'>Stanford University</h5>
                        <span className='block text-blue-600 f-raleway'>Stanford, CA </span>


                        <div className='grid grid-cols-12 my-3 gap-4'>
                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Avg. Price</span>
                                <NumberFormat prefix="₦" 
                                    displayType="text" 
                                    thousandSeparator={true}
                                    value={12000000} /> 
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Courses </span>
                                <bold className="block f-visby">300</bold>
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'> App. Fee </span>
                                <bold className="block f-visby">$200</bold>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className='md:col-span-4 col-span-12 mb-5'>
            <div className='shadow-lg rounded-b-md' style={{minHeight:'300px'}}>
                <div className=''>
                    <div className='imgBox'>
                        <img src={require('./../../dist/images/schools/yale.jpg')} className='imgFix' />
                    </div>

                    <div className='grid grid-cols-12 px-3' style={{marginTop: '-80px'}}>
                        <div className='col-span-6'>
                            <div className='collegeLogo'>
                                <img src={require('./../../dist/images/schools/logo/yale.jpg')} />
                            </div>
                        </div>

                        <div className='col-span-6 pt-4'>
                            <div className='h-16 w-16 py-2 text-center rounded-full bg-white shadow-lg float-right'>

                                <FontAwesome type='button' name="heart" size='1.5' className="text-2xl mt- text-blue-200" style={{marginTop: '14px'}} />
                            </div>
                        </div>
                    </div>

                    <div className='py-1 mt-2 px-4'>
                        <h5 className='workSanMedium text-gray-700'>University of Yale</h5>
                        <span className='block text-blue-600 f-raleway'>Canada</span>


                        <div className='grid grid-cols-12 my-3 gap-4'>
                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Avg. Price</span>
                                <NumberFormat prefix="₦" 
                                    displayType="text" 
                                    thousandSeparator={true}
                                    value={7000000} /> 
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Courses </span>
                                <bold className="block f-visby">300</bold>
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'> App. Fee </span>
                                <bold className="block f-visby">$200</bold>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className='md:col-span-4 col-span-12 mb-5'>
            <div className='shadow-lg rounded-b-md' style={{minHeight:'300px'}}>
                <div className=''>
                    <div className='imgBox'>
                        <img src={require('./../../dist/images/schools/chicago.jpg')} className='imgFix' />
                    </div>

                    <div className='grid grid-cols-12 px-3' style={{marginTop: '-80px'}}>
                        <div className='col-span-6'>
                            <div className='collegeLogo'>
                                <img src={require('./../../dist/images/schools/logo/chicago.jpg')} />
                            </div>
                        </div>

                        <div className='col-span-6 pt-4'>
                            <div className='h-16 w-16 py-2 text-center rounded-full bg-white shadow-lg float-right'>

                                <FontAwesome type='button' name="heart" size='1.5' className="text-2xl mt- text-blue-200" style={{marginTop: '14px'}} />
                            </div>
                        </div>
                    </div>

                    <div className='py-1 mt-2 px-4'>
                        <h5 className='workSanMedium text-gray-700'>The University of Chicago</h5>
                        <span className='block text-blue-600 f-raleway'>Chicago Illinois</span>


                        <div className='grid grid-cols-12 my-3 gap-4'>
                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Avg. Price</span>
                                <NumberFormat prefix="₦" 
                                    displayType="text" 
                                    thousandSeparator={true}
                                    value={7000000} /> 
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Courses </span>
                                <bold className="block f-visby">300</bold>
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'> App. Fee </span>
                                <bold className="block f-visby">$200</bold>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className='md:col-span-4 col-span-12 mb-5'>
            <div className='shadow-lg rounded-b-md' style={{minHeight:'300px'}}>
                <div className=''>
                    <div className='imgBox'>
                        <img src={require('./../../dist/images/schools/columbia.jpg')} className='imgFix' />
                    </div>

                    <div className='grid grid-cols-12 px-3' style={{marginTop: '-80px'}}>
                        <div className='col-span-6'>
                            <div className='collegeLogo'>
                                <img src={require('./../../dist/images/schools/logo/columbia.jpg')} />
                            </div>
                        </div>

                        <div className='col-span-6 pt-4'>
                            <div className='h-16 w-16 py-2 text-center rounded-full bg-white shadow-lg float-right'>

                                <FontAwesome type='button' name="heart" size='1.5' className="text-2xl mt- text-blue-200" style={{marginTop: '14px'}} />
                            </div>
                        </div>
                    </div>

                    <div className='py-1 mt-2 px-4'>
                        <h5 className='workSanMedium text-gray-700'>Columbia University, New York</h5>
                        <span className='block text-blue-600 f-raleway'>New York, USA</span>


                        <div className='grid grid-cols-12 my-3 gap-4'>
                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Avg. Price</span>
                                
                                <NumberFormat prefix="₦" 
                                    displayType="text" 
                                    thousandSeparator={true}
                                    value={7000000} /> 
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'>Courses </span>
                                <bold className="block f-visby">300</bold>
                            </div>

                            <div className='col-span-4 text-center'>
                                <span className='block text-gray-600 f-raleway'> App. Fee </span>
                                <bold className="block f-visby">$200</bold>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>


    </div>
                    </div>
                </div>
            </Structure>
            
        );
    }


}


export default Colleges