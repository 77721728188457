import React from 'react';
import cookie from 'js-cookie';
import { PaystackSecretTest } from '../keys/secret_keys';
//import { InternalSecurity, VerificationCode } from '../inc/Secret';


let token = cookie.get("token");






export async function GetApiPaystackBank(url=""){

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+PaystackSecretTest
        },
    })

    return response.json();
}



export async function GetApix(url=""){

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+token
        },
    })

    return response.json();
}


async function GetApi(url=""){

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer "+token
        },
    })

    return response.json();
}


export default GetApi



//with internal security
