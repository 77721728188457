import React, {Component} from 'react';
import Structure from '../../inc/structure';
import FeatherIcon from 'feather-icons-react';
import {Switch } from '@material-ui/core';
import { Nav, Tab } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import Modal from '../modals/Modal';
import AddSavings from '../modals/AddSavings';
import GetApi from '../../api_request/Get';
import { ServerLink } from '../../keys/Links';
import notifyError from '../../inc/SnackbarError';
import NumberFormat from 'react-number-format';
import FontAwesome from 'react-fontawesome';
import EditSavings from '../modals/EditSavings';
import AddMoney from '../modals/AddMoney';
import AddMoneyPurse from '../modals/AddMoneyPurse';


class Purse extends Component{



    constructor(props){
        super(props)

        this.state = {
            title:'Loading ....',
            campaign: false,
            modalx: true,
            modalToggle: false,
            modalSavings: false,
            modalSaveMoney: false,
            savings:'',
            isLoading: true,
            purseTarget: 0,
            totalSavings: 0,
            history:[],
            query: 'all',
        
        }


        

     this.getSaving()
    }



    componentDidMount(){

        //this.getSaving()
        
    }


    getSaving =(e)=>{

        //e.preventDefault()
        
        
        const { match: { params } } = this.props;

         //get the Card
         GetApi(ServerLink+'/user/purse/'+params.id).then(
            res=>{

                console.log(res)

                if(res.status == true){

                    this.setState({
                        savings: res.data[0],
                        history: res.history,
                        isLoading: false
                    })
                }
                else{

                    notifyError(res.message, 'error','top-center')
                    this.setState({
                        isLoading: false
                    })
                }
            }
        ).catch(e=>{
            console.log(e)

            //notifyError('Oops.. Something went wrong, check your internet connection', 'error','top-center')
            notifyError(e, 'error','top-center')
            this.setState({
                isLoading: false
            })
        })
    }



    modalHandler =(e)=>{
        e.preventDefault();

       
        
        this.setState(
            {
                modalToggle: !this.state.modalToggle,
               
            }
        )
    }

    modalAddSavings =(e)=>{
        e.preventDefault();

        this.setState(
            {
                modalSavings: !this.state.modalSavings, 
            }
        )
    }

    modalAddMoney=(e)=>{

        e.preventDefault();

        this.setState({
            modalSaveMoney: !this.state.modalSaveMoney
        })
    }




    ModalContent=()=>{


        if(this.state.modalSavings){

            return(

                <Modal show={this.state.modalSavings} modalClosed={this.modalAddSavings}>

                    <div>
                        <a onClick={this.modalAddSavings} className="float-right"><FeatherIcon icon="x-circle"  /></a>
                    </div>

                    <EditSavings data={this.state.savings} />
                </Modal>
            );


        }

        if(this.state.modalSaveMoney){


            return(

            <Modal show={this.state.modalSaveMoney} modalClosed={this.modalAddMoney}>

                    <div>
                        <a onClick={this.modalAddMoney} className="float-right text-red-700"><FeatherIcon icon="x-circle" className="text-red-700"  /></a>
                    </div>

                <AddMoneyPurse data={this.state.savings} />

            </Modal>
            )
        }


        if(this.state.modalToggle){

            return(
            
                    <Modal show={this.state.modalToggle} modalClosed={this.modalHandler}>
                            <div>
                                <a onClick={this.modalHandler} className="float-right"><FeatherIcon icon="x-circle"  /></a>

                                <div className="pt-40 px-3">
                                    <h5 className="workSanBold">AutoSave</h5>
                                    <p className="workSan">You can turn ON or OFF your AutoSave to your wallet any time</p>
                                
                                    <div className="mt-4">
                                        <Switch
                                            
                                            color={'primary'}
                                            // onChange={()=>alert('Hello')}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            defaultValue={'hhjhjjj'}
                                            
                                        />

                                        <small>Turn on autoSave</small>
                                        
                                    </div>

                                    <div className="mt-24">
                                        <button className="py-3 w-100 bg-blue-700 text-white text-center">Save Status</button>
                                    </div>
                                </div>

                            </div>
                    </Modal>
                )
        }
    }






    render(){

        const {savings, isLoading} = this.state

        return(
            <Structure>
                
                {this.ModalContent()}

                 <div className="cardx w-lg-max">

                    <div className="headerx">
                        <h2 className="font-bold">
                            {
                            isLoading ? 'Loading'

                            :
                             this.state.savings.title
                            }
                        </h2>
                        <p>{
                         this.state.savings.purpose
                        }</p>

                    </div>

                    

                    <div className="mt-20">
                        <div className="grid grid-cols-12 lg:gap-8 gap-5">
                            <div className="col-span-12 lg:col-span-9">

                                <div className="flex lg:flex-row flex-col gap-5">

                                    <div className="px-4 py-4 border border-gray-500 shadow-sm h-32">
                                        <p className="workSan">Target</p>
                                        <h4 className="workSan"> <sup className="inline-block sup">₦</sup><NumberFormat value={this.state.savings.target} displayType="text" thousandSeparator={true} className="text-black" /> </h4>
                                    </div>
                                

                                    <div className="px-4 py-4 border border-gray-500 shadow-sm h-32">
                                        <p className="workSan">Commitment Savings</p>
                                        <h4 className="workSan"> 
                                        <sup className="inline-block sup">₦</sup><NumberFormat value={this.state.savings.charge_amount} displayType="text" thousandSeparator={true} /><small className="text-xs">Per {this.state.savings.charge_period}</small>
                                        
                                        </h4>
                                    </div>


                                    <div className="px-4 py-4 border border-gray-500 shadow-sm h-32">
                                        <p className="workSan">Total Savings</p>
                                        <h4 className="workSan"> <sup className="inline-block sup">₦</sup><NumberFormat value={this.state.savings.balance} displayType="text" thousandSeparator={true} /> </h4>
                                    </div>


                                    <div className="px-4 py-4 border border-gray-500 shadow-sm h-32">
                                        <p className="workSan">Savings Period</p>
                                        <h4 className="workSan"> <NumberFormat value={this.state.savings.timeline_goal} displayType="text" thousandSeparator={true} /> <sup className="inline-block sup">Months</sup></h4>
                                    </div>


                                </div>
                                

                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <div>
                                    <div>
                                        <p>
                                           You can modify your purse to increase your target and commitment
                                        </p>

                                        <div className="w-full">
                                            <button  onClick={this.modalAddMoney}  className="w-full flex bg-blue-700 py-3 px-4 hover:text-blue-400 text-white hover:bg-black hover:border-2 hover:border-indigo-800">
                                               <span className="inline-block mr-4">Save Money</span> <FeatherIcon icon="plus" classNam="inline-bloc ml-2" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="mt-10 grid grid-cols-12 gap-5">

                        <div className="col-span-12 lg:col-span-3">
                            <div className="shadow-md" style={{'minHeight':'190px'}}>


                                {
                                    this.state.savings.autosave == true

                                    ?
                                

                                <div className="px-2">

                                     <span className="font-bold inline-block py-1 px-2 uppercase rounded-full text-white bg-blue-800" style={{'fontSize':'10px'}}>
                                        Active
                                    </span>

                                    <p className="mt-3">
                                        Your puse Status is Currently Active you can deactivate it to pause your automatic savings.
                                    </p>

                                    <div className="w-full text-center">
                                        <button  onClick={this.modalHandler}  className="w-full flex bg-blue-700 py-3 px-4 hover:text-blue-400 text-white hover:bg-black hover:border-2 hover:border-indigo-800">
                                            <span className="inline-block mr-4">Turn off Autosave</span> <FeatherIcon icon="plus" classNam="inline-bloc ml-2" />
                                        </button>
                                    </div>
                                </div>

                                :


                                <div className="px-2">

                                    <span className="font-bold inline-block py-1 px-2 uppercase rounded-full text-white bg-yellow-600" style={{'fontSize':'10px'}}>
                                    Pending
                                </span>

                                <p className="mt-3">
                                    Your purse status is currently pending you can activate it to start your automatic savings.
                                </p>

                                <div className="w-full text-center">
                                    <button  onClick={this.modalHandler}  className="w-full flex bg-blue-700 py-3 px-4 hover:text-blue-400 text-white hover:bg-black hover:border-2 hover:border-indigo-800">
                                        <span className="inline-block mr-4">Turn on Autosave</span> <FeatherIcon icon="plus" classNam="inline-bloc ml-2" />
                                    </button>
                                </div>
                            </div>


                            }






                            </div>
                        </div>

                        <div className="col-span-12 lg:col-span-3">
                             <div className="shadow-md" style={{'minHeight':'190px'}}>

                                <div className="px-2">

                                    <span className="font-bold inline-block py-1 px-2 uppercase rounded-full text-white bg-black" style={{'fontSize':'10px'}}>
                                        Edit
                                    </span>
                                    <p className="mt-3">
                                        You can modify your purse to increase your target and commitment
                                    </p>

                                    <div className="w-full">
                                        <button  onClick={this.modalAddSavings}  className="w-full flex bg-black py-3 px-4 hover:text-blue-400 text-white hover:bg-blue-800 hover:border-2 hover:border-indigo-800">
                                            <span className="inline-block mr-4">Edit Purse</span> <FeatherIcon icon="edit" classNam="inline-bloc ml-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 lg:col-span-3" hidden>
                             <div className="shadow-md" style={{'minHeight':'190px'}}>

                                <div className="px-2">

                                    <span className="font-bold inline-block py-1 px-2 uppercase rounded-full text-white bg-red-600" style={{'fontSize':'10px'}}>
                                        Delete
                                    </span>
                                    <p className="mt-3">
                                        You can delete this purse to totally suspend all your savings, Note: Be very sure and convince about your action
                                    </p>

                                    <div className="w-full">
                                        <button  onClick={this.modalAddSavings}  className="w-full flex bg-red-700 py-3 px-4 hover:text-blue-400 text-white hover:bg-blue-800 hover:border-2 hover:border-indigo-800">
                                            <span className="inline-block mr-4">Delete Purse</span> <FeatherIcon icon="trash" classNam="inline-bloc ml-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>



                         <div className="col-span-12 lg:col-span-6 bg-gray-100 py-3 px-3">

                            <div className="mt-1 mb-4">
                                <h5 className="mb-0 pb-0">History</h5>
                            </div>

                            {this.state.history.map((history, index)=>(

                                <div className="w-full mb-3 h-20 shadow-lg flex flex-row gap-4 py-3 px-4 bg-white">
                                <div className="rounded-full bg-blue-200 h-10 pt-2 px-2 w-10">
                                        <FontAwesome name="piggy-bank" className="f-s-20 text-blue-500" rotate="270" flip="horizontal" />
                                    </div>
                                    <div>
                                        <h5 className="mb-0 pb-0"><NumberFormat value={history.amount} displayType='text' thousandSeparator={true} prefix="₦" /></h5>
                                        <p className="text-gray-400 text-xs">Savings Successfull</p>
                                    </div>
                                    <div className="right flex-auto">
                                        <div className="float-right">
                                            {history.date}
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>

                        

                        

                    </div>


                   



                    <div className="grid grid-cols-12 lg:gap-8 gap-5 mt-3 ">

                       


                        <div className="col-span-12 lg:col-span-4">

                            

                        </div>

                    </div>

                    

                </div>
            </Structure>
        );
    }
}

export default Purse;