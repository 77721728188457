import  React, {Component} from 'react';
import { ToastContainer, toast } from 'react-toastify';

export default function notifyError(info, typex, directionx=""){

  var positionx =  "top-right";

  if(directionx !==""){

     positionx =  directionx
  }

  toast(info, {
            
  position: positionx,
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  type: typex

  })
}



