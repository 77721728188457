import React, {Component} from 'react';
import NumberFormat from 'react-number-format';
import Slider from 'react-rangeslider';
import DatePicker from 'react-date-picker';
import FeatherIcon from 'feather-icons-react';
import {connect } from 'react-redux';
 
// To include the default styles
import 'react-rangeslider/lib/index.css'
import DebitCard from '../payment/DebitCard';
import GetApi from '../../api_request/Get';
import { ServerLink, RedirectLink } from '../../keys/Links';
import notifyError from '../../inc/SnackbarError';
import postApi from '../../api_request/Post';
import { Spinner } from 'react-bootstrap';
 

class AddSavings extends Component{

    constructor(props, context) {
        super(props, context)
        this.state = {
          setMonths: 6,
          amount: 200000,
          title:'',
          purpose:'',
          startDate: '',
          autosave: '',
          autosaveAmount: '',
          authorization_id: 'new',
          dueDate: '',
          period: '',
          step: 1,
          cards:[],
          isLoading: true,

        }
      }


    componentDidMount(){

        //get the Card
        GetApi(ServerLink+'/cards').then(
            res=>{
                //console.log(res)

                if(res.status == true){

                    this.setState({
                        cards: res.cards,
                        isLoading: false
                    })
                }
                else{

                    notifyError(res.error, 'error','top-center')


                    this.setState({
                        isLoading: false
                    })
                }
            }
        ).catch(e=>{
            console.log(e)

            notifyError('Oops.. Something went wrong, check your internet connection', 'error','top-center')
            this.setState({
                isLoading: false
            })
        })

    }


      handleOnChange = (value) => {
        //this.addMonths(this.state.startDate, this.state.setMonths)
        
        this.setState({
          setMonths: value,
          //dueDate:  new Date(this.state.startDate.getTime() + (this.state.setMonths * 30 * 24 * 60 * 60 * 1000))
        })
      }

      handleDateChange = (e) =>{

        e.preventDefault();

        let sDate = document.getElementById('startDate').value;

        this.setState({
            startDate: sDate,
            
            
        })


        //console.log(date)
          
      }


      addMonths = (date, months) => {
        
        //get the day of the month
        var d = date.getDate();

        date.setMonth(date.getMonth() + +months);

        if (date.getDate() != d) {
          date.setDate(0);
        }


        return date;
    }  


    handleInput = (i) => {
        i.preventDefault();
        const name = i.target.name;
        const value = i.target.value;
        this.setState({[name]:value});

        //console.log(value)
    }

    handleSubmit = (e)=>{

        e.preventDefault();


        if(this.state.step === 1){


            if(this.state.amount =="" || this.state.autosave =="" || this.state.setMonths==""){ // || this.state.startDate==""

                notifyError('Please fill all the form fields as appropriate', 'error', 'top-right');
            }
            else{

                var autoSave = this.state.autosave;
                var temp = new Array();
                temp = autoSave.split("-");
                

                    this.setState({
                    step: 2,
                    period: temp[1],
                    autosaveAmount: temp[0],
                    //dueDate: new Date(this.state.startDate.getTime() + (this.state.setMonths * 30 * 24 * 60 * 60 * 1000))
                    })

                    if(temp[1] =='Daily'){

                        this.setState({
                            startDate: '12:00'
                        });
                    }
                    else if(temp[1] =='Weekly'){

                        this.setState({
                            startDate: 'Sunday'
                        });
                    }

        }

    }


    if(this.state.step === 2){


        if( this.state.startDate==""){

            notifyError('Please fill all the form fields as appropriate', 'error', 'top-right');
        }

        else{



        this.setState({
            isLoading: true
        })


        const data  = {
            'title': this.state.title,
            'purpose':this.state.purpose,
            'target_amount': this.state.amount,
            'timeline_goal': this.state.setMonths,
            'charge_amount': this.state.autosaveAmount,
            'charge_period': this.state.period,
            'authorization_id': this.state.authorization_id,
            'start_date': this.state.startDate,
            'autosave':'true',
            'redirect_url': RedirectLink+'/#/dashboard',
            'callback_url': ServerLink+'/payment/transaction_target',
            'webredirect_url': RedirectLink+'/#/savings',
            
        }


        //console.log(data);

        postApi(ServerLink+'/payment/create_savings', data).then(

            res=>{

                if(res.status == 'success' && this.state.authorization_id == 'new'){
                    //console.log(res.data.authorization_url)
                    window.location.replace(res.data.link);
                }



                else if(res.status == 'success' && this.state.authorization_id !== 'new'){

                   notifyError(res.message, 'success', 'top-right')

                    this.setState({
                        isLoading: false
                    })

                    setTimeout(() => {
                        //window.location.replace(RedirectLink+'/#/history');
                        window.location.reload();
                    }, 6000);
                }


                console.log(res);
            }
        ).catch(e=>{

            notifyError(e, 'error', 'top-right');

            this.setState({
                isLoading: false
            })

            console.log(e)

        })

    }
}

    }



    currentStep = e =>{

        const {setMonths} = this.state;

        if(this.state.step === 1){

            return(

                <form onSubmit={this.handleSubmit}>

                    <div className="my-5 text-center">
                        <h3 className="workSanBold">Create a new Savings account</h3>
                        <p className="workSan"></p>
                    </div>

                    <div className="py-4">
                        <label className="workSanMedium">Title</label>
                        <input name="title" defaultValue={this.state.title} onChange={this.handleInput} className="px-2 py-3 border w-full placeholder-gray-600" type="text" placeholder="e.g Seun School Fees" />
                    </div>




                    <div className="">
                        <label className="workSanMedium">Taget Amount</label>


                        <div className="flex flex-row">
                            <select name="currency" className="px-2 py-3  focus:outline-none border-b w-20 f-s-30 placeholder-gray-600 bg-gray-200" reado>
                                
                                <option value={this.props.userInfo.currency} selected>{this.props.userInfo.currency_symbol }</option>
                            </select>

                            <NumberFormat 
                            thousandSeparator={true} 
                            displayType='input' 
                            className="flex-shrink-0 px-2 py-3 f-s-30 border-b w-11/12 placeholder-gray-600 bg-gray-200" 
                            value={this.state.amount}
                            onValueChange={(values) => {
                                const {formattedValue, value} = values;
                                this.setState({amount: value})
                        }}

                        />


                        </div>

                        

                    

                        <input hidden name="amount" onChange={this.handleInput} className="px-2 py-3 border w-full placeholder-gray-600 bg-gray-200" defaultValue={this.state.amount} type="number" placeholder="300000" />

                    </div>


                    <div className="mt-5">

                        <label className="workSanMedium">Select Time Frame</label>
                        <Slider
                            value={setMonths}
                            orientation="horizontal"
                            onChange={this.handleOnChange}
                            max={36}
                            min={1}
                        />

                        <h5 className="float-right text-right workSanMedium text-gray-700">{setMonths} Months</h5>
                    </div>

                    


                    <div className="mt-16">
                        <label className="workSanMedium">Set Automatic savings</label>

                        <div className="myRadio">

                            <div class="flex items-center mr-4 mb-4">
                                <input id="radio1" type="radio" name="autosave" value={Math.ceil(this.state.amount/this.state.setMonths)+'-Monthly'} className="hidden" onChange={this.handleInput} />
                                <label htmlFor="radio1" class="flex items-center cursor-pointer text-xl">
                                
                                <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                                    Save <b className="workSanBold bg-none noBg"><NumberFormat thousandSeparator={true} displayType="text" prefix={this.props.userInfo.currency_symbol }  value={Math.ceil(this.state.amount/this.state.setMonths)} /></b> Monthly 
                                </label>
                            </div>
{/* 
                            <div class="flex items-center mr-4 mb-4">
                                <input id="radio2" type="radio" name="autosave" value={ Math.ceil(this.state.amount/this.state.setMonths / 2)+'-BiWeekly'} className="hidden" onChange={this.handleInput} />
                                <label htmlFor="radio2" class="flex items-center cursor-pointer text-xl">
                                <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                                    Save <b className="workSanBold bg-none noBg"><NumberFormat thousandSeparator={true} displayType="text" prefix={this.props.userInfo.currency_symbol}  value={ Math.ceil(this.state.amount/this.state.setMonths / 2)} /></b> Biweekly
                                </label>
                            </div> */}

                            <div class="flex items-center mr-4 mb-4">
                                <input id="radio3" type="radio" name="autosave" value={ Math.ceil(this.state.amount/this.state.setMonths / 4)+'-Weekly'} className="hidden" onChange={this.handleInput} />
                                <label htmlFor="radio3" class="flex items-center cursor-pointer text-xl">
                                <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                                    Save <b className="workSanBold bg-none noBg"><NumberFormat thousandSeparator={true} displayType="text" prefix={this.props.userInfo.currency_symbol}  value={ Math.ceil(this.state.amount/this.state.setMonths / 4)} /></b> Weekly 
                                </label>
                            </div>

                            <div class="flex items-center mr-4 mb-4">
                                <input id="radio4" type="radio" name="autosave" value={Math.ceil((this.state.amount/this.state.setMonths / 4)/7)+'-Daily'}  className="hidden" onChange={this.handleInput} />
                                <label htmlFor="radio4" class="flex items-center cursor-pointer text-xl">
                                <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                                Save <b className="workSanBold bg-none noBg"><NumberFormat thousandSeparator={true} displayType="text" prefix={this.props.userInfo.currency_symbol}  value={Math.ceil((this.state.amount/this.state.setMonths / 4)/7)} /></b> Daily 
                                </label>
                            </div>

                        </div>

                    </div>



                    <div className="" hidden>
                        <label className="workSanBold">Savings End Date</label>
                        <input name="end" className="py-3 px-2 border w-full bg-gray-200" defaultValue={this.state.dueDate} readOnly />
                    </div>




                    <div className="py-3">
                        <label className="workSanMedium">Purpose of savings</label>
                        <textarea className="w-full border px-2 py-3" name="purpose" onChange={this.handleInput}>{this.state.purpose}</textarea>

                    </div>


                    <div className="mb-20">
                        <button className="w-full py-3 text-center px-2 bg-blue-800 text-white mt-2">
                            Add Card
                        </button>
                    </div>
                
                </form>
            )
        }

        if(this.state.step === 2){
            return(

           <div className="">
                <button className="" style={{marginTop: '-40px !important'}} onClick={()=>(this.setState({step: 1}))}>
                    <FeatherIcon icon="arrow-left" className="inline-block ml-3" /> <span className="workSanMedium"> Go Back</span>
                </button>

               <h5 className="workSanBold text-center mb-3">Add Card</h5>
            <div className="">
               
                <div>
                    <form onSubmit={this.handleSubmit}>
                    <div className="mt-5 mb-2">
                        <label className="workSanMedium">Add Credit Card</label>
                        <select className="px-2 py-3 border w-full" name="authorization_id" onChange={this.handleInput}>
                            <option value="new">New Card</option>
                            {
                                this.state.cards.map((card, index)=> (
                                <option value={card.authorization_id}> *** {card.last4} </option>
                            ))}
                        </select>

                        {
                            this.state.authorization_id =='new' ?
                    
                        <div className="bg-red-100 p-3 mt-3">
                            <p className="workSanMedium">Note: For adding a New Card, ₦1,000 will be charged and added to your Study Purse</p>
                        </div>

                        :
                        null
                        }

                    </div>



                    <div className="bg-gray-200 px-3 py-5">

                        <div className="">
                            <label>Title:</label>
                            <h5 className="f-w-700 flex-shrink-0">
                               {this.state.title}
                            </h5>
                        </div>

                        <div className="">
                            <label>Target:</label>
                            <h5 className="f-w-700 flex-shrink-0">
                                <NumberFormat className="bg-none" displayType="text" thousandSeparator={true} prefix="₦" value={this.state.amount} />
                            </h5>
                        </div>

                        <div className="mt-3">
                            <label>{this.state.period} Savings:</label>
                            <h5 className="f-w-700">
                                <NumberFormat className="bg-none" displayType="text" thousandSeparator={true} prefix="₦" value={this.state.autosaveAmount} />
                            </h5>
                        </div>


                        <div className="mt-3">
                            <label> Charges and VAT:</label>
                            <h5 className="f-w-700">
                                <NumberFormat className="bg-none" displayType="text" thousandSeparator={true} prefix="₦" value={this.state.autosaveAmount*0.014+500} />
                            </h5>
                        </div>

                        <div className="mt-3">
                            <label> Total Charges:</label>
                            <h5 className="f-w-700">
                                <NumberFormat className="bg-none" displayType="text" thousandSeparator={true} prefix="₦" value={this.state.autosaveAmount*0.014+200+parseInt(this.state.autosaveAmount)} />
                            </h5>
                        </div>




                        

                        <div className="mt-3">
                            <label>Target Timeline:</label>
                            <h5 className="f-w-700">{this.state.setMonths} Months</h5>
                        </div>

                        <div className="mt-3">
                            <label>Saving State Date: </label>
                            <b className="mt-0 block">{this.state.startDate}</b>
                        </div>

                        <div className="mt-3" hidden>
                            <label className="text-red-500">Saving End Date: </label>
                            <b className="mt-2 block">{this.state.dueDate}</b>
                        </div>

                        <div className="mt-3">
                            <label>Purpose of Savings:</label>

                            <p className="f-w-700">
                               {this.state.purpose}
                            </p>
                        </div>



                    <div className="mt-3">
                        


                        {
                            this.state.period =='Monthly' 
                            ?
                            <>
                            <label className="workSanBold">Select Savings Day of the {this.state.period} </label>
                            <input type="date" id="startDate" defaultValue={this.state.startDate} className="px-2 py-3 w-full border mb-3" onChange={this.handleDateChange}  />
                            </>
                            :
                            this.state.period =='Weekly' 
                            
                            ?
                            <>
                            <label className="workSanBold text-blue-700">Select the {this.state.period} Saving Day  </label>
                            <select id="startDate" className="px-2 py-3 w-full border mb-3" onChange={this.handleDateChange}>
                                <option value="Sunday">Sunday</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednessday">Wednessday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                            </select>
                            </>

                            :
                            <>
                            <label className="workSanBold">The {this.state.period} Saving Time  </label>
                            <input type="time" id="startDate" defaultValue={this.state.startDate} className="px-2 py-3 w-full border mb-3" onChange={this.handleDateChange} readOnly  />
                            </>
                        }
                        


                    </div>
                        
                    </div>



                    <div className="mb-20">
                        { this.state.isLoading ?
                            <button type="button" className="w-full py-3 text-center px-2 bg-blue-300 text-white mt-2"> 
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                                /> 
                            
                                            Loading...
           
                            </button>
                            :
                            <button type="submit" className="w-full py-3 text-center px-2 bg-blue-800 text-white mt-2">Set Target and Start Saving
                            </button>
                        }
                    </div>
                
                </form>

                </div>
            </div>
                
            </div> 

            );
        }
    }
    


    render(){
        let { setMonths } = this.state
        return(
            <div>
                {this.currentStep()}
                
            </div>
        )
    }
}


const mapStateToProps = state => {

    return {
        userInfo: state.auth.user
    }
}



export default connect(mapStateToProps, '')(AddSavings);




//export default